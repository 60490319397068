import React from "react"
import {Link} from "gatsby"
import SEO from "../../components/seo"
import styled from "styled-components";
import Img from "gatsby-image";
import "../../style/style.css"
const PageContainer = styled.div`
  width: calc(100vw - 80px);
  height: 100vh;
  padding: 40px;
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  background: #000000;
  color: #FFFFFF;
`
const LinkStyled = styled(Link)`
  padding-left: 40px;
  padding-top: 20px;
  color: #FFFFFF;
`
const TitleContainer = styled.div`
  font-size: 30px;
  font-weight: 700;
  width: 100%;
`
const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`

export default ({data}) => {
  return(
    <PageContainer>
      <SEO title="Waist" />
      <LogoContainer>
        <Img fixed={data.file.childImageSharp.fixed}/>
      </LogoContainer>
      <TitleContainer>Language</TitleContainer>
      <LinkStyled to={"/waist/en/"}>INSTRUCTIONS - EN</LinkStyled>
      <LinkStyled to={"/waist/fr/"}>INSTRUCTIONS - FR</LinkStyled>
      <LinkStyled to={"/waist/es/"}>INSTRUCCIONES - ES</LinkStyled>
      <LinkStyled to={"/waist/jp/"}>取扱説明書 - JP</LinkStyled>
      <LinkStyled to={"/waist/kr/"}>사용설명서 - KR</LinkStyled>
      <LinkStyled to={"/waist/cn/"}>使用说明书 - CN</LinkStyled>
    </PageContainer>
  )}
export const query = graphql`
  query {
    file(relativePath: { eq: "waist-logo-white.png" }) {
      childImageSharp {
        fixed(width: 250, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
